/* eslint-disable @typescript-eslint/no-explicit-any */
import type { CountryCode } from 'libphonenumber-js/types';
import { FilterValue } from '../components/UserInfoProperty/types';
import {
    WorkflowSchedule,
    WorkflowSpec,
    WorkflowTrigger,
} from '../components/workflows/types';
import { Column } from '../main/contacts-import/CsvImportWizard/utils';
import { UUID } from '../types/uuid';

export enum AttributeType {
    date = 'date',
    email = 'email',
    multi_select = 'multi_select',
    phone = 'phone',
    select = 'select',
    text = 'text',
}

export enum PstnCallType {
    UserOut = 'oc_user_out',
    UserIn = 'oc_user_in',
    OcUserOutConf = 'oc_user_out_conf',
    OcUserInConf = 'oc_user_in_conf',
}

export enum Feature {
    AddInbox = 'add_inbox',
    AiWorkflow = 'ai_workflow',
    Analytics = 'analytics',
    AutoConnectOutlook = 'auto_connect_outlook',
    BlockList = 'block_list',
    BrandOptInIntegration = 'brand_opt_in_integration',
    CallingStatistics = 'calling_statistics',
    Campaigns = 'campaigns',
    Classifications = 'classifications',
    ConversationSummary = 'conversation_summary',
    CreateNewNumber = 'create_new_number',
    ImportNumber = 'import_number',
    InboxSearch = 'inbox_search',
    InviteTeammate = 'invite_teammate',
    ManageBilling = 'manage_billing',
    ManageInbox = 'manage_inbox',
    AnalyticsReports = 'analytics_reports',
    MessageTemplates = 'message_templates',
    NewInbox = 'new_inbox',
    NextContacts = 'next_contacts',
    RemoveTeammate = 'remove_teammate',
    SmarshIntegration = 'smarsh_integration',
    AutoJoinSameWhitelistedDomain = 'auto_join_same_whitelisted_domain',
    Workflows = 'workflows',
}

type PropertyId = UUID;

type PropertyValue = any;

export type Contact = {
    id: UUID;
    name?: string;
    data: Record<PropertyId, PropertyValue>;
    phone?: string;
    email?: string;
    optOut?: boolean;
    avatarURL?: string;
    updated: string;
    created: string;
    createdByUserId?: string;
    public: boolean;
};

export interface Credentials {
    email: string;
}

export type LoginCredentials = Credentials;

export enum InboxProvider {
    TWILIO = 'twilio',
    BANDWIDTH = 'bandwidth',
    MSTEAMS = 'msteams',
    OTHER = 'other',
    NON_VOIP = 'non_voip',
    TELNYX = 'telnyx',
    ZOOM = 'zoom',
    WEBEX = 'webex',
}

export enum InboxStatus {
    PENDING = 'pending',
    ACTIVE = 'active',
    DISABLED = 'disabled',
    PORT_SUBMITTED = 'port_submitted',
    PORT_FAILED = 'port_failed',
}

export interface Inbox {
    id: UUID;
    icon?: string;
    phone: string;
    name?: string;
    status: InboxStatus;
    public: boolean;
    verified: boolean;
    description: string;
    provider: InboxProvider;
    locationType?: 'USER' | 'IVR';
    requestedActivationDate?: Date;
    pin?: string;
    assistantEnabled: boolean;
    aiPipelineCount: number;
}

export type InboxAssignment = {
    userId: string;
    inboxId: string;
};

export enum CohortStatus {
    OK = 'ok',
    SCHEDULED = 'scheduled',
    RUNNING = 'running',
    ERROR = 'error',
}

export interface CohortMeta {
    id: number;
    userId: string;
    name: string;
    isPublic: boolean;
    updated: string;
    contactsCount: number;
}

export interface Cohort extends CohortMeta {
    query?: FilterValue[];
    queryTerm?: string;
    contactIds: string[];
    included: string[];
    excluded: string[];
    contacts?: Contact[];
    status: CohortStatus;
}

export interface Invite {
    id: string;
    phone?: string;
    email?: string;
    role: RoleTypes;
    inboxIds: string[];
}

export enum BillingProvider {
    Stripe = 'stripe',
    Shopify = 'shopify',
}

export interface OnboardingProgress {
    inboxActivation?: boolean;
    teamsLicense?: boolean;
    brandRegistration?: boolean;
    integration?: boolean;
}

export interface Team {
    id: string;
    name?: string;
    dlcCampaignId?: string;
    slackTeamId: string;
    countryCode: CountryCode;
    teamAvatarURL?: string;
    integrationsHiddenByDefault: boolean;
    contactsPublicByDefault: boolean;
    whitelistedIntegrations: IntegrationSource[];
    billingProvider: BillingProvider;
    smsUsed: number;
    smsLimit: number;
    tier: 'free' | 'starter' | 'unlimited' | 'mfa_nonvoip';
    tierId: UUID;
    invites: Invite[];
    users?: Profile[];
    assistantResponseDelaySeconds: number;
    assistantInstruction: string | null;
    onboarding: OnboardingProgress;
    created: Date;
    stripeSubscriptionId?: string;
    resellerId?: UUID;
    microsoftTenantId?: UUID;
    whitelistDomains: string[];
    whitelistDisabled: boolean;
}

interface PersonalInfo {
    avatar?: { url?: string };
    avatarId?: string;
    firstname: string;
    lastname: string;
    phone: string;
    company: string;
}

export enum IntegrationSource {
    Azure = 'azure',
    CSV = 'csv',
    CampaignRegistry = 'campaignregistry',
    Dynamics = 'dynamics',
    GlobalRelay = 'globalrelay',
    Google = 'google',
    Hubspot = 'hubspot',
    Linkedin = 'linkedin',
    Microsoft = 'microsoft',
    Pipedrive = 'pipedrive',
    Purview = 'purview',
    Salesforce = 'salesforce',
    Shopify = 'shopify',
    Slack = 'slack',
    Smarsh = 'smarsh',
    Stripe = 'stripe',
}

export enum IntegrationTypes {
    Contact = 'contact',
    Connection = 'connection',
    Logs = 'logs',
    Application = 'application',
    Data = 'data',
}

export interface IntegrationSettings {
    propertyMap: Column[];
}

export enum IntegrationStatus {
    Fail = 'fail',
    Success = 'success',
    Disconnected = 'disconnected',
    Syncing = 'syncing',
}

export interface BaseIntegration {
    id: string;
    integrationSource: Exclude<IntegrationSource, 'Smarsh'>;
    integrationType: IntegrationTypes;
    public: boolean;
    credentials: Record<string, any>;
    settings: IntegrationSettings;
    lastSyncStatus: IntegrationStatus;
    lastSyncedAt: string;
    userId: UUID;
}

export interface SmarshIntegration extends BaseIntegration {
    integrationSource: IntegrationSource.Smarsh;
    integrationType: IntegrationTypes.Logs;
    credentials: { rcptTo: string };
}

export type Integration = BaseIntegration | SmarshIntegration;

export enum UserTeamSettingType {
    Signature = 'signature',
    InboxesOrder = 'inboxes_order',
}

export type UserTeamSetting = {
    userId: string;
    teamId: string;
    data?: Record<string, any>;
    enabled?: boolean;
    type: UserTeamSettingType;
};

interface BaseProfile {
    activeTeam: Team;
    activeRole?: Role;
    activeSetting: UserTeamSetting;
    id: UUID;
    email: string;
    roles: Array<Role>;
    microsoftGraphAccessToken?: string | null;
    microsoftId?: string;
    microsoftTenantId?: string;
    mutedInboxIds: string[];
    acceptedTermsOfService: boolean;
    created: Date;
    updated: Date;
    features: { enabled: boolean; name: Feature }[];
}

export type Profile = BaseProfile & PersonalInfo;

export interface Phone {
    externalId: string;
    phone: string;
    inboxesName: string;
}

export interface Attachment {
    id: UUID;
    url: string;
    name: string;
    type: string;
    size: number;
}

/** @deprecated try to use Attachment instead */
export interface PublicFile {
    id: string;
    url: string;
    size: number;
    name?: string;
    type?: string;
}

export interface MessageComment {
    id: number;
    discussionId: number;
    text: string;
    userId: UUID;
    mentionedUserIds?: UUID[];
    created: Date;
    attachments?: PublicFile[];
}

export interface Discussion {
    id: number;
    messageId: number;
    replies: number;
    lastReplyDate?: Date;
    message: Message;
    comments: MessageComment[];
}

export enum SenderType {
    USER = 'user',
    CAMPAIGN = 'campaign',
    CAMPAIGN_MESSAGE = 'campaign_message',
    WORKFLOW = 'workflow',
    ASSISTANT = 'assistant',
}

export enum MessageStatus {
    Sending = 'sending',
    Sent = 'sent',
    Delivered = 'delivered',
    Failed = 'failed',
    Read = 'read',
    Scheduled = 'scheduled',
}

export interface Unfurl {
    icon: string;
    siteName: string;
    title: string;
    image?: string;
    description?: string;
    url: string;
}

export type Message = {
    id: number;
    conversationId: UUID;
    created: string;
    inbound: boolean;
    body: string;
    userId?: UUID;
    user?: Profile;
    sender: string;
    status: MessageStatus;
    sentByName: string;
    senderType: SenderType;
    attachments?: PublicFile[];
    attached: Attachment[];
    discussion?: Discussion;
    timestamp: string | Date;
    unfurl?: Record<string, Unfurl>;
};

export enum ConversationStatus {
    Active = 'active',
    Archived = 'archived',
    Unread = 'unread',
    Deleted = 'deleted',
}

export interface Conversation {
    created: number | Date;
    updated: number | Date;
    inboxId: string;
    id: string;
    members: string[];
    hasUnread: boolean;
    assistantDisabled: boolean;
    lastMessageSent?: string;
    lastMessageBody?: string;
    lastMessageAttachmentsCount: number;
    unreadMessageCount: number;
    isLastMessageInbound: boolean;
    inbox: Inbox;
    name: string;
    status: ConversationStatus;
    assignee?: Profile[];
    assistantTimestamp: string | null;
    assistantMessageId: number | null;
}

export enum ConversationsFilterType {
    Active = 'active',
    Archived = 'archived',
    Unread = 'unread',
    ByCohorts = 'by_cohorts',
    AssignedToMe = 'assigned_to_me',
}

export type CohortFilter = {
    type: ConversationsFilterType.ByCohorts;
    cohortsList: Pick<CohortMeta, 'id'>[];
};
type SimpleFilter = {
    type: Exclude<ConversationsFilterType, ConversationsFilterType.ByCohorts>;
};
export type ConversationsFilter = CohortFilter | SimpleFilter;

export interface Verification {
    email: string;
    method_id: string;
    code: string;
    microsoftTenantId?: string;
}

export type BandwidthPhone = Array<string>;

export type UnreadMessageRecord = {
    userId: string;
    conversationId: string;
    inboxId: string;
    messageId: string;
};

export enum ScheduledMessageStatus {
    OK = 'ok',
    SCHEDULED = 'scheduled',
    ERROR = 'error',
}

/** @deprecated use Message with timestamp instead */
export interface ScheduledMessage {
    id: number;
    body?: string;
    sendAt: string;
    status: ScheduledMessageStatus;
    error: string;
    userId?: UUID;
    user?: Profile;
    attachments: PublicFile[];
}

export interface CreateScheduledMessageDto {
    body: string;
    conversationId: string;
    sendAt: string;
    files?: string[];
}

export interface UpdateScheduledMessageDto {
    id: number;
    body?: string;
    sendAt: string;
    files?: string[];
}

export enum RoleTypes {
    WorkspaceOwner = 'workspace_owner',
    Admin = 'admin',
    Agent = 'agent',
    Manager = 'manager',
    Member = 'member',
}

export enum ClerkPermission {
    AddTeamMembers = 'add_team_members',
    BrandAccess = 'brand_access',
    CreateTeam = 'create_team',
    DeleteTeam = 'delete_team',
    DeleteConversation = 'delete_conversation',
    DeleteMessage = 'delete_message',
    ExportInboxLogs = 'export_inbox_logs',
    ModifyPrivateInbox = 'modify_private_inbox',
    ModifyPublicInbox = 'modify_public_inbox',
    ModifyTeam = 'modify_team',
    ViewInboxMessages = 'view_inbox_messages',
    ViewPublicInbox = 'view_public_inbox',
}

export interface Role {
    type: RoleTypes;
    permissions: ClerkPermission[];
    teamId: string;
}

export interface CreateMessageTemplateDto {
    name: string;
    body: string;
    isPersonal: boolean;
}

export interface UpdateMessageTemplateDto {
    id: number;
    name: string;
    body: string;
    isPersonal: boolean;
}

export interface MessageTemplate {
    id: number;
    name: string;
    body: string;
    userId: string | null;
    externalId: string;
}

export interface CreateWorkflowDto {
    name: string;
    spec: WorkflowSpec;
    trigger: WorkflowTrigger;
    schedules?: WorkflowSchedule[];
    inboxId?: string;
    enabled?: boolean;
}

export interface AttachWorkflowDto {
    workflowId: number;
    inboxId: string;
}

export interface RenameWorkflowDto {
    workflowId: number;
    name: string;
}

export interface TeamForAuth {
    id: string;
    name?: string;
    teamAvatarURL?: string;
    members: number;
    memberAvatars: string[];
    membersForAvatars: {
        firstname: string | null;
        lastname: string | null;
        avatarUrl: string | null;
    }[];
}

export enum CompanyIdentifyType {
    DUNS = 'DUNS',
    GIIN = 'GIIN',
    LEI = 'LEI',
}

export enum CompanyEntityType {
    SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
    PRIVATE_PROFIT = 'PRIVATE_PROFIT',
    PUBLIC_PROFIT = 'PUBLIC_PROFIT',
    NON_PROFIT = 'NON_PROFIT',
    GOVERNMENT = 'GOVERNMENT',
}

export enum BrandRegistrationStatus {
    SELF_DECLARED = 'SELF_DECLARED',
    UNVERIFIED = 'UNVERIFIED',
    PENDING = 'PENDING',
    FAILED = 'FAILED',
    VERIFIED = 'VERIFIED',
    VETTED_VERIFIED = 'VETTED_VERIFIED',
}

export enum CompanyVertical {
    PROFESSIONAL = 'PROFESSIONAL',
    REAL_ESTATE = 'REAL_ESTATE',
    HEALTHCARE = 'HEALTHCARE',
    HUMAN_RESOURCES = 'HUMAN_RESOURCES',
    ENERGY = 'ENERGY',
    ENTERTAINMENT = 'ENTERTAINMENT',
    RETAIL = 'RETAIL',
    TRANSPORTATION = 'TRANSPORTATION',
    AGRICULTURE = 'AGRICULTURE',
    INSURANCE = 'INSURANCE',
    POSTAL = 'POSTAL',
    EDUCATION = 'EDUCATION',
    HOSPITALITY = 'HOSPITALITY',
    FINANCIAL = 'FINANCIAL',
    POLITICAL = 'POLITICAL',
    GAMBLING = 'GAMBLING',
    LEGAL = 'LEGAL',
    CONSTRUCTION = 'CONSTRUCTION',
    NGO = 'NGO',
    MANUFACTURING = 'MANUFACTURING',
    GOVERNMENT = 'GOVERNMENT',
    TECHNOLOGY = 'TECHNOLOGY',
    COMMUNICATION = 'COMMUNICATION',
}

export enum OptInStatus {
    Init = 'init',
    Running = 'running',
    PageNotAccessible = 'page_not_accessible',
    WidgetNotFound = 'widget_not_found',
    WidgetMalfunction = 'widget_malfunction',
    Failed = 'failed',
    Success = 'success',
}

export interface Brand {
    id: number;
    brandId?: string;
    companyName?: string;
    brandName?: string;
    registrationCountry?: string;
    organizationLegalForm?: CompanyEntityType;
    ein?: string;
    einCountry?: string;
    organizationIdentifyType: CompanyIdentifyType;
    altBusinessId?: string;
    companyCountry?: 'US' | 'CA';
    state?: string;
    city?: string;
    companyZip?: string;
    registrationErrors?: string[];
    companyAddress?: string;
    website?: string | null;
    organizationVertical: CompanyVertical;
    companyInfo?: string;
    contactFirstName?: string;
    contactLastName?: string;
    contactPhone?: string;
    contactEmail?: string;
    status?: BrandRegistrationStatus | null;
    teams: Team[];
    optInStatus: OptInStatus;
    optInVerifiedAt?: string;
}

export type CreateBrandDto = Partial<Brand>;
export type UpdateBrandDto = { id: number; brand: CreateBrandDto };

export interface ProfanityRecord {
    blackList: string[];
}

export enum ContactPropertyType {
    Text = 'text',
    Number = 'number',
    Select = 'select',
    MultiSelect = 'multi_select',
    Date = 'date',
    Person = 'person',
    FilesAndMedia = 'files',
    Boolean = 'boolean',
    URL = 'url',
    Email = 'email',
    Phone = 'phone',
    CreatedTime = 'created_time',
    LastEditedTime = 'last_edited_time',
    LastEditedBy = 'last_edited_by',
    LastContactedBy = 'last_contacted_by',
}

type PhoneBase = {
    phone: string;
    type: 'USER' | 'IVR' | string;
};

export type TeamsPhone = PhoneBase & {
    user?: {
        displayName: string;
        objectId: string;
        userPrincipalName: string;
    };
};

export type TeamsUser = {
    microsoftTenantId: string;
    displayName: string;
    email: string;
};

export type TeamsPhoneResponse = {
    phones: TeamsPhone[];
    activationAt?: Date;
};

type WebexUser = {
    id: string;
    emails: string[];
    orgId: string;
    firstName: string;
    lastName: string;
};

export type WebexPhone = PhoneBase & {
    numberType: string;
    user?: WebexUser;
    primary: boolean;
};

export type WebexPhoneResponse = {
    phones: WebexPhone[];
};

export enum ErrorCodes {
    PaidTeamRequired = 'PAID_TEAM_REQUIRED',
    CampaignBrandRegistrationRequired = 'CAMPAIGN_BRAND_REGISTRATION_REQUIRED',
    MessageLimitReached = 'SMS_LIMIT_REACHED',
    CannotDeleteLastTeam = 'CANNOT_DELETE_LAST_TEAM',
}
export enum Period {
    Day = '1 day',
    Hour = '1 hour',
    Week = '1 week',
    Month = '1 month',
}

export interface TimePointStats {
    date: string;
    count: number;
    duration?: number;
}

export interface TeamStats {
    incoming: TimePointStats[];
    outgoing: TimePointStats[];
    totalIncoming: number;
    totalOutgoing: number;
    total: number;
    totalDuration?: number;
    period: Period;
    callTypeFilters?: PstnCallType[];
    users?: UserCallsStats[];
}

export interface UserCallsStats {
    numberOfCalls: number;
    totalDuration: number;
    userId: UUID;
}

export interface TeammateCallsStats {
    avatar: string;
    name: string;
    numberOfCalls: number;
    averageDuration: string;
    totalDuration: string;
    userId?: string;
}

export type TeamStatsFilter = {
    start: Date;
    end: Date;
    users?: UUID[];
    inboxes?: UUID[];
    callTypes?: PstnCallType[];
};

export interface Classifier {
    id: string;
    name: string;
}

export interface Classification {
    id: string;
    value: string;
    classifier: Pick<Classifier, 'name'>;
}

export interface Pipeline {
    id: UUID;
    teamId: UUID;
    name: string;
    created: string;
    updated: string;
    description?: string;
    inboxSubscriptions: string[];
}
