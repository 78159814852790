import { useNavigate } from 'react-router';
import { ClerkPermission } from '../../api/types';
import logo from '../../elements/IconComponents/clerk-logo-header.svg';
import { useMeQueryData } from '../../queries/user';
import {
    Avatar,
    Box,
    Button,
    Divider,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
} from '@mui/material';
import { userEmail } from '../../helpers/formatting';
import { useListTeams, useSwitchTeamQuery } from '../../queries/team';
import { useMenu } from '../../hooks/use-menu';
import { ExpandMoreFilledIcon } from '../../icons/common/ExpandMoreFilled';
import { pluralize } from '../../utils/pluralize';
import { setAuthToStorage } from '../../api/team';
import { queryClient } from '../../queries/queryClient';
import { signOut } from '../../api/auth';
import { ActiveIcon } from './ActiveIcon';
import { UserIcon } from '../../containers/SettingsLayout/Navigation/icons/UserIcon';
import { Link } from 'react-router-dom';
import { NotificationsIcon } from '../../containers/SettingsLayout/Navigation/icons/NotificationsIcon';
import { InformationIcon } from '../../icons/common/InformationIcon';
import { WithPermission } from '../../containers/WithPermission/WithPermission';
import { CompanyIcon } from '../../icons/common/CCompanyIcon';
import { MembersIcon } from '../../icons/common/MembersIcon';
import { useTrack } from '../../contexts/analytics';
import {
    avatar,
    limitedName,
    teamName,
    teamSelectorBtn,
    buttonLikeStyle,
} from './styles';
import { MailboxIcon } from '../../icons/common/MailboxIcon';
import { useBetweenMdLg } from '../../hooks/useBetweenMdLg';

const DEFAULT_NAME = 'Team';

export const TeamSelector = () => {
    const track = useTrack();
    const navigate = useNavigate();
    const me = useMeQueryData();
    const { anchorEl, open, handleOpen, handleClose } = useMenu();
    const { data: teams = [] } = useListTeams();
    const { mutateAsync: setTeam } = useSwitchTeamQuery();
    const isNarrowScreen = useBetweenMdLg();

    return (
        <>
            <Button
                sx={
                    isNarrowScreen
                        ? {
                              justifyContent: 'center',
                              '& > .MuiButton-icon': {
                                  marginRight: 0,
                              },
                          }
                        : teamSelectorBtn
                }
                variant="text"
                onClick={handleOpen}
                fullWidth
                startIcon={
                    <Avatar
                        sx={{ width: 20, height: 20 }}
                        alt={me?.activeTeam?.name || DEFAULT_NAME}
                        src={me?.activeTeam.teamAvatarURL || logo}
                    />
                }
                endIcon={
                    isNarrowScreen ? undefined : (
                        <Box sx={buttonLikeStyle}>
                            <ExpandMoreFilledIcon />
                        </Box>
                    )
                }
            >
                {!isNarrowScreen && (
                    <Box
                        display="flex"
                        flexDirection="column"
                        ml={2}
                        overflow="hidden"
                        flexGrow={1}
                    >
                        <Typography variant="body3" sx={teamName}>
                            {me?.activeTeam?.name || DEFAULT_NAME}
                        </Typography>
                        <Typography
                            variant="body4"
                            width="100%"
                            textAlign="left"
                            color="text.secondary"
                            noWrap
                        >
                            {userEmail(me!)}
                        </Typography>
                    </Box>
                )}
            </Button>
            <Menu
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                slotProps={{
                    paper: {
                        sx: {
                            width: 240,
                            mt: -1,
                        },
                    },
                }}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top',
                }}
            >
                <Typography
                    variant="body4"
                    color="custom.gray.super"
                    sx={{
                        display: 'block',
                        px: 2,
                        mt: 2,
                        mb: 1,
                    }}
                >
                    My {pluralize(teams.length, 'team', 'teams', 'team', '')}
                </Typography>
                {teams.map((team) => (
                    <MenuItem
                        key={team.id}
                        selected={team.id === me?.activeTeam?.id}
                        onClick={() => {
                            track('team_switched', {
                                teamId: team.id,
                            });
                            setTeam(team.id).then((data) => {
                                setAuthToStorage(data);
                                navigate('/');
                                queryClient.resetQueries();
                            });
                            handleClose();
                        }}
                        sx={{ overflow: 'hidden' }}
                    >
                        <ListItemIcon>
                            <Avatar
                                sx={avatar}
                                alt={team?.name || DEFAULT_NAME}
                                src={team?.teamAvatarURL || logo}
                            />
                        </ListItemIcon>

                        <ListItemText
                            primaryTypographyProps={{
                                sx: limitedName,
                            }}
                        >
                            {team.name ?? 'Team'}
                        </ListItemText>
                    </MenuItem>
                ))}
                <Divider />
                <Typography
                    variant="body4"
                    color="custom.gray.super"
                    sx={{
                        display: 'block',
                        px: 2,
                        mt: 2,
                        mb: 1,
                    }}
                >
                    Account settings
                </Typography>
                <MenuItem
                    component={Link}
                    to="/settings/profile"
                    onClick={() => {
                        track('user_menu_clicked', { target: 'profile' });
                    }}
                >
                    <ListItemIcon>
                        <UserIcon />
                    </ListItemIcon>
                    <ListItemText>My Profile</ListItemText>
                </MenuItem>
                <MenuItem
                    component={Link}
                    to="/settings/notifications"
                    onClick={() => {
                        track('user_menu_clicked', { target: 'notifications' });
                    }}
                >
                    <ListItemIcon>
                        <NotificationsIcon />
                    </ListItemIcon>
                    <ListItemText>Notifications</ListItemText>
                </MenuItem>
                <WithPermission
                    requiredPermissions={[ClerkPermission.ModifyTeam]}
                >
                    <MenuItem
                        component={Link}
                        to="/settings/team"
                        onClick={() => {
                            track('user_menu_clicked', { target: 'team' });
                        }}
                    >
                        <ListItemIcon>
                            <CompanyIcon />
                        </ListItemIcon>
                        <ListItemText>Team Info</ListItemText>
                    </MenuItem>
                </WithPermission>
                <WithPermission
                    requiredPermissions={[ClerkPermission.ModifyTeam]}
                >
                    <MenuItem
                        component={Link}
                        to="/settings/members"
                        onClick={() => {
                            track('user_menu_clicked', { target: 'members' });
                        }}
                    >
                        <ListItemIcon>
                            <MembersIcon />
                        </ListItemIcon>
                        <ListItemText>Members</ListItemText>
                    </MenuItem>
                </WithPermission>
                <Divider />
                <MenuItem
                    href="https://support.clerk.chat/Clerk-Help-Center-cea421f30cf64e579bd15851de5dfc2c"
                    target="_blank"
                    rel="noreferrer noopener"
                    component="a"
                    onClick={() => {
                        track('user_menu_clicked', { target: 'support' });
                    }}
                >
                    <ListItemIcon>
                        <InformationIcon />
                    </ListItemIcon>
                    <ListItemText>Get Support ↗</ListItemText>
                </MenuItem>
                <MenuItem
                    href="https://feedback.clerk.chat/"
                    target="_blank"
                    rel="noreferrer noopener"
                    component="a"
                    onClick={() => {
                        track('user_menu_clicked', { target: 'feedback' });
                    }}
                >
                    <ListItemIcon>
                        <MailboxIcon />
                    </ListItemIcon>
                    <ListItemText>Leave feedback ↗</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem
                    sx={{
                        color: 'error.main',
                    }}
                    onClick={async () => {
                        track('sign_out');
                        await signOut();
                    }}
                >
                    <ListItemIcon>
                        <ActiveIcon sx={{ color: 'error.main' }} />
                    </ListItemIcon>
                    <ListItemText>Log out</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
};
