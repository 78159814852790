import { useState } from 'react';
import { Button, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { AvailableIntegration } from './integrations-list';
import {
    IntegrationSource,
    IntegrationTypes,
    Profile,
} from '../../../api/types';
import { createIntegrationUrl, createMSAppIntegrationUrl } from './utils';
import * as styles from './styles';
import { ConnectShopifyIntegrationModal } from '../../../components/Modals/ConnectShopifyIntegrationModal/ConnectShopifyIntegrationModal';
import { ConnectDynamicsIntegrationModal } from '../../../components/Modals/ConnectDynamicsIntegrationModal/ConnectDynamicsIntegrationModal';
import { ConnectAzureIntegrationModal } from '../../../components/Modals/ConnectAzureIntegrationModal/ConnectAzureIntegrationModal';
import { useNavigate } from 'react-router';
import { EmailConnectDialog } from './EmailConnectDialog';
import { useSetupEmailIntegration } from './hooks/useSetupEmailIntegration';
import { useTrack } from '../../../contexts/analytics';

type Props = AvailableIntegration & {
    me: Profile;
};

/**
 *  @deprecated this thing is little broken some composition or factory needs to be used instead
 *  if you are struggling with usage, just rewrite the component
 * */
export const IntegrationRow = ({ me, ...integration }: Props) => {
    const { icon, name, desc, extraSettings, source, integrationType } =
        integration;
    const [showModal, setShowModal] = useState(false);
    const closeModal = () => setShowModal(false);
    const navigate = useNavigate();
    const { mutateAsync: setupEmailIntegration } = useSetupEmailIntegration();
    const track = useTrack();

    const clickHandler = (e: MouseEvent) => {
        if (
            extraSettings &&
            [
                IntegrationSource.Shopify,
                IntegrationSource.Smarsh,
                IntegrationSource.Dynamics,
                IntegrationSource.Azure,
                IntegrationSource.Purview,
            ].includes(source)
        ) {
            e.preventDefault();
            setShowModal(true);
            return;
        }
        if (source === 'csv') {
            e.preventDefault();
            navigate('/contacts/book/import-csv');
        }
    };

    return (
        <>
            <ListItem sx={styles.listItem}>
                <ListItemAvatar>
                    <img src={icon} alt={name} />
                </ListItemAvatar>
                <ListItemText
                    primary={name}
                    primaryTypographyProps={{
                        variant: 'body3',
                        color: 'primary.dark',
                        sx: { marginBottom: 1 },
                    }}
                    secondary={desc}
                    secondaryTypographyProps={{
                        noWrap: true,
                        variant: 'body4',
                        color: 'custom.gray.super',
                    }}
                />
                <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    target={source !== 'csv' ? '_blank' : undefined}
                    sx={styles.connectButton}
                    onClick={clickHandler}
                    href={
                        source === 'csv'
                            ? '/contacts/book/import-csv'
                            : source === IntegrationSource.Microsoft &&
                                integrationType === IntegrationTypes.Data
                              ? createMSAppIntegrationUrl(integration, me!)
                              : createIntegrationUrl(integration, me!)
                    }
                >
                    Connect
                </Button>
            </ListItem>
            {source === IntegrationSource.Smarsh && (
                <EmailConnectDialog
                    onClose={closeModal}
                    open={showModal}
                    title="Connect Smarsh"
                    subtitle="Please provide destination address provided by Smarsh"
                    placeholder="Email Address"
                    onSubmit={(email) => {
                        setupEmailIntegration({
                            email,
                            source: IntegrationSource.Smarsh,
                        }).then(() => {
                            track('connect_smarsh_integration_submitted');
                        });
                    }}
                    onOpen={() => {
                        track('connect_smarsh_integration_opened');
                    }}
                />
            )}

            {source === IntegrationSource.Purview && (
                <EmailConnectDialog
                    onClose={closeModal}
                    open={showModal}
                    title="Connect Purview"
                    subtitle="Please provide an email destination address that Purview will scan"
                    placeholder="Email Address"
                    onSubmit={(email) => {
                        setupEmailIntegration({
                            email,
                            source: IntegrationSource.Purview,
                        }).then(() => {
                            track('connect_smarsh_integration_submitted');
                        });
                    }}
                    onOpen={() => {
                        track('connect_purview_integration_opened');
                    }}
                />
            )}

            {source === IntegrationSource.Azure && (
                <ConnectAzureIntegrationModal
                    onClose={closeModal}
                    open={showModal}
                    integration={integration}
                />
            )}
            {source === IntegrationSource.Shopify && (
                <ConnectShopifyIntegrationModal
                    onClose={closeModal}
                    open={showModal}
                />
            )}

            {source === IntegrationSource.Dynamics && (
                <ConnectDynamicsIntegrationModal
                    onClose={closeModal}
                    open={showModal}
                    integration={integration}
                    me={me!}
                />
            )}
        </>
    );
};
