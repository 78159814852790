import { Box, Tooltip, Typography } from '@mui/material';
import InviteMsTeamsUserModal from '../../../components/Modals/InviteMsTeamsUserModal/InviteMsTeamsUserModal';
import { useState } from 'react';
import { useMeQueryData } from '../../../queries/user';
import { RoleTypes } from '../../../api/types';
import { InviteUserModal } from '../../../components/Modals/InviteUserModal/InviteUserModal';
import { useBetweenMdLg } from '../../../hooks/useBetweenMdLg';
import { InviteButton } from './InviteButton';
import { useTrack } from '../../../contexts/analytics';

export const SideBarInvite = () => {
    const [showInviteModal, setShowInviteModal] = useState(false);
    const track = useTrack();
    const me = useMeQueryData();
    const isNarrowScreen = useBetweenMdLg();

    const handleClick = () => {
        track('clicked_invite_team');
        setShowInviteModal(true);
    };

    return (
        <>
            <Box
                sx={{
                    px: 4,
                    py: 3,
                    borderBottom: '1px solid',
                    borderBottomColor: 'custom.gray.divider',
                }}
            >
                {isNarrowScreen ? (
                    <Tooltip
                        disableFocusListener
                        arrow
                        title={
                            <Typography noWrap variant="body4">
                                Invite team
                            </Typography>
                        }
                        placement="right"
                    >
                        <InviteButton onClick={handleClick} />
                    </Tooltip>
                ) : (
                    <InviteButton onClick={handleClick} />
                )}
            </Box>
            {[RoleTypes.WorkspaceOwner, RoleTypes.Admin].includes(
                me?.activeRole?.type as RoleTypes,
            ) ? (
                <InviteUserModal
                    open={showInviteModal}
                    onClose={() => setShowInviteModal(false)}
                />
            ) : (
                <InviteMsTeamsUserModal
                    open={showInviteModal}
                    onClose={() => setShowInviteModal(false)}
                />
            )}
        </>
    );
};
