import { Outlet } from 'react-router';
import { SetupWebexInboxProvider } from './setup-webex-phone-context';
import { WebexPhone } from '../../../api/types';

export type WebexPhoneNumbersProps = {
    selectedPhones?: WebexPhone[];
    setSelectedPhones?: (phone: WebexPhone) => void;
    setActivationDate?: (date: Date) => void;
    activationDate?: Date;
    goForward: () => void;
    goBack?: () => void;
};

export const WebexPhoneNumbers = () => {
    return (
        <SetupWebexInboxProvider>
            <Outlet />
        </SetupWebexInboxProvider>
    );
};
