import { Theme, useMediaQuery } from '@mui/material';

export const HideDownMd = ({
    children,
}: {
    children: React.ReactNode;
}): null | React.ReactNode => {
    const isUpMd = useMediaQuery<Theme>((theme) =>
        theme.breakpoints.down('md'),
    );
    return isUpMd ? null : children;
};
