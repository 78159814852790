import { QueryKey, useMutation, useQuery } from '@tanstack/react-query';
import {
    ConnectIntegrationRequest,
    getIntegrationRemoteSchema,
    getMicrosoftTeamsPhones,
    getMicrosoftTeamsUsers,
    getWebexPhones,
    requestMsTeamsAdminAccess,
    syncIntegration,
    updateIntegration,
} from '../api/integrations';
import { AxiosError, AxiosResponse } from 'axios';
import {
    Integration,
    TeamsPhoneResponse,
    TeamsUser,
    WebexPhoneResponse,
} from '../api/types';
import { queryClient } from './queryClient';
import createClient, { client } from '../api/http';
import { useTrack } from '../contexts/analytics';

export const INTEGRATIONS_LIST_QUERY_KEY: QueryKey = ['integrations-list'];

export const useGetIntegrationRemoteSchema = (integrationId: string) => {
    return useQuery({
        queryKey: ['integration', integrationId, 'remote-properties'],
        queryFn: () => getIntegrationRemoteSchema({ id: integrationId }),
    });
};

export const useGetMicrosoftTeamsPhones = () => {
    return useQuery<TeamsPhoneResponse, AxiosError>({
        queryKey: ['microsoft-teams-phones'],
        queryFn: () => getMicrosoftTeamsPhones(),
        retry: false,
        refetchOnWindowFocus: 'always',
    });
};

export const useGetWebexPhones = () => {
    return useQuery<WebexPhoneResponse, AxiosError>({
        queryKey: ['webex-phones'],
        queryFn: () => getWebexPhones(),
        retry: false,
        refetchOnWindowFocus: 'always',
    });
};

export const useUpdateIntegration = () =>
    useMutation({
        mutationFn: updateIntegration,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: INTEGRATIONS_LIST_QUERY_KEY,
            });
        },
    });

export const useDisconnectIntegration = () =>
    useMutation({
        mutationFn: ({ id }: { id: string }) =>
            client.delete(`integrations/${id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: INTEGRATIONS_LIST_QUERY_KEY,
            });
        },
    });

export const useConnectIntegration = () => {
    const track = useTrack();

    return useMutation<unknown, AxiosResponse, ConnectIntegrationRequest>({
        mutationFn: ({ source, code }: ConnectIntegrationRequest) =>
            client
                .post(`integrations/setup/${source}`, { code })
                .then(({ data }) => data),
        onSuccess: (_, { source }) => {
            track('integration_completed', {
                source,
            });

            queryClient.invalidateQueries({
                queryKey: INTEGRATIONS_LIST_QUERY_KEY,
            });
        },
    });
};

export const useListIntegrations = (enabled = true) =>
    useQuery({
        queryKey: INTEGRATIONS_LIST_QUERY_KEY,
        queryFn: () =>
            client.get<Integration[]>('integrations').then(({ data }) => data),
        enabled,
    });

export const useSyncIntegration = () =>
    useMutation({
        mutationFn: syncIntegration,
    });

export type CreateAzureIntegrationData = {
    storageContainer: string;
    storageAccount: string;
    storageAccessKey: string;
};

export const useCreateAzureIntegration = () =>
    useMutation<
        void,
        AxiosError<{ message: string }>,
        CreateAzureIntegrationData
    >({
        mutationKey: ['create-azure-integration'],
        mutationFn: async (data: {
            storageContainer: string;
            storageAccount: string;
            storageAccessKey: string;
        }) => {
            await createClient().post<void>('/integrations/setup/azure', data);
        },
    });

export const useRequestMsTeamsAdminAccess = () =>
    useMutation({
        mutationFn: requestMsTeamsAdminAccess,
    });

export const useGetMicrosoftTeamsUsers = () => {
    return useQuery<TeamsUser[], AxiosError>({
        queryKey: ['microsoft-teams-users'],
        queryFn: getMicrosoftTeamsUsers,
        retry: false,
    });
};
