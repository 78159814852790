import { useTeams } from 'msteams-react-base-component';
import Loading from '../../components/Loading/Loading';
import useUnmutedSortedInboxes from '../../hooks/useUnmutedSortedInboxes';
import { useMeQueryData } from '../../queries/user';
import { Redirect } from '../../utils/redirect';
import { ClerkPermission } from '../../api/types';
import { usePermission } from '../WithPermission/WithPermission';

export const InboxLoader = () => {
    const currentUser = useMeQueryData();
    const { inboxes, unmutedSortedInboxes, isPending } =
        useUnmutedSortedInboxes();

    const [{ inTeams }] = useTeams();

    const canSetupInboxes = usePermission(ClerkPermission.ModifyTeam);

    if (isPending) {
        return <Loading />;
    }

    if (currentUser) {
        if (inboxes.length < 1) {
            const isSlackUser = currentUser.activeTeam.slackTeamId;

            if (!canSetupInboxes || isSlackUser) {
                return <Redirect to="/contacts/book" />;
            }

            if (inTeams) {
                return <Redirect to="/setup-inbox/ms-teams/auth" />;
            }

            return <Redirect to="/setup-inbox" />;
        }

        if (unmutedSortedInboxes.length < 1) {
            return <Redirect to="/contacts/book" />;
        }

        return <Redirect to={`/inbox/${unmutedSortedInboxes[0].id}`} />;
    }

    return null;
};
