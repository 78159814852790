import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Inbox } from '../../api/types';
import { formatPhoneNumber } from '../../utils/phoneNumber';
import { Box, ListItemText, Stack, SxProps, Typography } from '@mui/material';
import { renderInboxIcon } from '../../utils/inboxes';
import { useTrack } from '../../contexts/analytics';
import { MuteIcon } from '../../components/Inbox/CMuteIcon';
import { getSquare } from '../../theme/style.helpers';
import { useMeQueryData } from '../../queries/user';
import { useMenu } from '../../hooks/use-menu';
import { inboxItem } from './styles';
import { Indicators } from './Indicators';
import { ContextMenuButton } from './ContextMenuButton';
import { InboxIconSelector } from './InboxIconSelector';
import { getPath } from './utils';
import { InboxContextMenu } from '../../components/Inbox/InboxContextMenu';

interface Props {
    inbox: Inbox;
    onClick?: () => void;
    sx?: SxProps;
    isPending?: boolean;
    disableMenus?: boolean;
}

const INBOX_NAME_PLACEHOLDER = 'Inbox';

export const InboxItem = ({
    inbox,
    onClick,
    sx = inboxItem,
    disableMenus = false,
}: Props) => {
    const currentUser = useMeQueryData();
    const track = useTrack();
    const menu = useMenu();
    const { id, icon, phone = '', name = INBOX_NAME_PLACEHOLDER } = inbox;
    const isMuted = currentUser?.mutedInboxIds?.includes(inbox.id);

    useEffect(() => {
        if (menu.open) {
            track('inbox_icon_picker_open', {
                inboxId: id,
                origin: 'sidebar inbox list',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menu.open]);

    const inboxName = (
        <Typography
            data-testid="inbox-li-name"
            variant="body3"
            color="primary.dark"
        >
            {name || INBOX_NAME_PLACEHOLDER}
        </Typography>
    );

    return (
        <>
            <Box
                component={NavLink}
                sx={sx}
                onClick={onClick}
                to={getPath(inbox)}
            >
                {!disableMenus ? (
                    <InboxIconSelector inbox={inbox} />
                ) : (
                    <Box sx={{ fontSize: 20, ml: 2, mr: 2, p: 1 }}>
                        {renderInboxIcon(icon)}
                    </Box>
                )}

                <ListItemText
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                    data-testid="inbox-item"
                    primary={
                        isMuted ? (
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                            >
                                <Box
                                    sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {inboxName}
                                </Box>
                                <Box component="span" display="inline-flex">
                                    <MuteIcon
                                        sx={{
                                            ...getSquare(12),
                                            color: 'custom.gray.super',
                                        }}
                                    />
                                </Box>
                            </Stack>
                        ) : (
                            inboxName
                        )
                    }
                    secondary={formatPhoneNumber(
                        phone,
                        currentUser?.activeTeam?.countryCode,
                    )}
                    secondaryTypographyProps={{
                        /* @ts-ignore */
                        'data-testid': 'inbox-li-phone',
                        variant: 'body4',
                        color: 'custom.gray.super',
                    }}
                />

                {!disableMenus && (
                    <ContextMenuButton inboxId={inbox.id} menu={menu} />
                )}
                <Indicators inbox={inbox} isHidden={menu.open} />
            </Box>
            {!disableMenus && (
                <InboxContextMenu inboxId={inbox.id} menu={menu} />
            )}
        </>
    );
};

export default InboxItem;
