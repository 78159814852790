import {
    listItemAvatarClasses,
    listItemTextClasses,
    SxProps,
    Theme,
} from '@mui/material';

export const listItem: SxProps<Theme> = (theme) => ({
    padding: theme.spacing(4, 0),
    borderBottom: '1px solid',
    borderBottomColor: 'custom.gray.tableDivider',
    alignItems: 'center',

    [`& .${listItemAvatarClasses.root}`]: {
        display: 'flex',
        minWidth: 'auto',
        marginRight: 4,

        '& img': {
            maxWidth: 40,
        },
    },

    [`& .${listItemTextClasses.root}`]: {
        margin: theme.spacing(0, 6, 0, 0),
    },
});

export const connectButton: SxProps = {
    minWidth: 96,

    '&:visited': {
        color: 'primary.dark',
    },
};

export const comingSoon: SxProps<Theme> = {
    padding: (theme) => theme.spacing(3.5, 4),
    border: '1px solid',
    borderColor: 'custom.gray.light400',
    borderRadius: 1.5,

    '&:hover': {
        cursor: 'pointer',
        borderColor: 'info.main',
    },

    [`& img`]: {
        width: 20,
    },
};
