import { Inbox, InboxStatus } from '../../api/types';
import dayjs from 'dayjs';

export function getStatusText(status: InboxStatus, inbox: Inbox): string {
    switch (status) {
        case InboxStatus.PENDING:
            return 'Please click to verify phone';
        case InboxStatus.PORT_SUBMITTED:
            return `Verified. Your number will be active on ${
                inbox.requestedActivationDate
                    ? dayjs(inbox.requestedActivationDate).format(
                          'MMMM D, hh:mm A',
                      )
                    : 'the next business day'
            }`;
        case InboxStatus.PORT_FAILED:
            return 'Verification failed. Please schedule onboarding here https://calendly.com/clerkchat/verify';
        default:
            return '';
    }
}

export const getPath = (inbox: Inbox) => {
    switch (inbox.status) {
        case InboxStatus.ACTIVE:
            return `/inbox/${inbox.id}`;
        case InboxStatus.PENDING:
            return `/inbox/${inbox.id}/verify/customer`;
        case InboxStatus.PORT_SUBMITTED:
            return '#';
        default:
            return '#';
    }
};
