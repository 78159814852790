/* eslint-disable react-hooks/exhaustive-deps */
import { Editable, ReactEditor, Slate } from 'slate-react';
import { renderLeaf } from './renderLeaf';
import { CustomEditor, Serializer } from './slate';
import { renderElement } from './renderElement';
import { EditableProps } from 'slate-react/dist/components/editable';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    compareValues,
    createInitialValue,
    focusEditor,
    insertNodes,
    isEmpty,
    resetEditorValue,
} from './utils';
import { MessageFormData } from '../MessageForm';
import { Box, Stack } from '@mui/material';
import { Attachment, MessageTemplate, PublicFile } from '../../api/types';
import { Descendant } from 'slate';
import { UUID } from '../../types/uuid';
import * as styles from './styles';
import { TextSerializer } from './serializers/text';
import cc from 'classcat';
import { useProperties } from './components/Toolbar/Template/useProperties';
import { insertSnippet } from './components/Toolbar/Snippet/insertSnippet';
import {
    useDeleteAttachment,
    useUploadAttachment,
} from '../MessageForm/use-attachment';
import { AttachmentList } from './components/Toolbar/FileUploader/AttachmentList';
import { linkDecorator } from '../NewConversation/utils';
import { Placeholder } from './components/Placeholder/Placeholder';
import { useConversation } from '../../queries/conversations';
import { useGetMessages } from '../../queries/messages';
import { Toolbar } from './components/Toolbar/Toolbar';
import { useEmojiAutocomplete } from './components/Toolbar/Emoji/useEmojiAutocomplete';
import { SearchEmojiPicker } from './components/Toolbar/Emoji/SearchEmojiPicker';
import { QueueList } from './QueueList';
import reject from 'lodash/reject';
import { DropZone } from '../../pages/NewInbox/DropZone/DropZone';
import { createPortal } from 'react-dom';
import { useGoToQueryParam } from '../../pages/NewInbox/query.helpers';
import { useSearchParams } from 'react-router-dom';
import { QueryParam } from '../../pages/NewInbox/query.params';
import { useBetweenMdXl } from '../../hooks/useBetweenMdXl';

export type EditorData = MessageFormData & {
    value: Descendant[];
};

export type MessageInputProps = Omit<
    EditableProps,
    'renderLeaf' | 'onChange' | 'onValueChange' | 'renderElement' | 'onBlur'
> & {
    autoFocus?: boolean;
    editor: CustomEditor;
    toolbar?: (
        | 'emojiPicker'
        | 'templates'
        | 'signature'
        | 'snippets'
        | 'schedule'
    )[];
    initialValue?: string | Descendant[];
    initialAttachments?: Attachment[];
    /** @deprecated use attachments instead*/
    initialFiles?: PublicFile[];
    sendAt?: string;
    onChange?: (data: {
        value: Descendant[];
        files?: PublicFile[];
        attachments?: Attachment[];
    }) => void;
    onCancel?: () => void;
    onBlur?: (message: Descendant[]) => void;
    withFileUploader?: boolean;
    withAiAssistant?: boolean;
    edit?: boolean;
    conversationId?: UUID;
    withSendButton?: boolean;
    onSend?:
        | ((data: EditorData) => Promise<unknown>)
        | ((data: EditorData) => void);
    serializer?: Serializer<string | Descendant[]>;
    height?: number | string;
    transparentToolbar?: boolean;
};

export function MessageInput({
    editor,
    autoFocus = false,
    toolbar = [],
    withFileUploader = false,
    withAiAssistant = false,
    initialValue,
    initialFiles,
    initialAttachments,
    sendAt,
    conversationId,
    onSend,
    onChange,
    onCancel,
    withSendButton = false,
    edit = false,
    serializer = TextSerializer,
    height,
    disabled = false,
    transparentToolbar = false,
    onBlur,
    ...editableProps
}: MessageInputProps) {
    const isBetweenMdXl = useBetweenMdXl();
    const goToQueryParam = useGoToQueryParam();
    const [searchParams] = useSearchParams();
    const { messages } = useGetMessages(conversationId ?? '');
    const { data: conversation, isPending: isConversationPending } =
        useConversation(conversationId);
    const { mutateAsync: uploadAttachment } = useUploadAttachment();
    const { mutate: deleteAttachment } = useDeleteAttachment();
    const { regEx: propertiesRegEx } = useProperties();
    const [value, setValue] = useState<Descendant[]>(
        serializer.deserialize(initialValue ?? '', propertiesRegEx),
    );
    const [files, setFiles] = useState<PublicFile[]>(initialFiles ?? []);
    const [attachments, setAttachments] = useState<Attachment[]>(
        initialAttachments ?? [],
    );
    const [queued, setQueued] = useState<File[]>([]);
    const [hasError, setHasError] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const [template, setTemplate] = useState<MessageTemplate>();
    const {
        emojis,
        onChange: emojiChangeHandler,
        onKeyDown: emojiKeyDownHandler,
        searchString: emojiSearchString,
        searchIndex: emojiSearchIndex,
        targetRange: emojiTargetRange,
        handleInsertEmoji,
    } = useEmojiAutocomplete(editor);

    const dropZoneParent = document.getElementById('dropzone-parent');

    useEffect(() => {
        setAttachments(initialAttachments ?? []);
    }, [
        initialAttachments
            ?.map(({ id }) => id)
            ?.sort()
            .join('_'),
    ]);

    const removeFile = (id: string) => {
        deleteAttachment(id);
        setAttachments((prev) => prev.filter((item) => item.id !== id));
    };

    const onDrop = (files: File[]) => {
        files.forEach((file) => handleUpload(file));
    };

    useEffect(() => {
        if (!initialValue) {
            return;
        }

        if (
            typeof initialValue === 'string' &&
            initialValue !== serializer?.serialize(value)
        ) {
            insertSnippet(editor, initialValue, propertiesRegEx);
            return;
        }

        if (
            Array.isArray(initialValue) &&
            !compareValues(value, initialValue)
        ) {
            insertNodes(editor, initialValue, true);
        }
    }, [initialValue, editor, propertiesRegEx]);

    useEffect(() => {
        if (autoFocus && !disabled) {
            focusEditor(editor);
        }
    }, [autoFocus, editor, initialValue]);

    const resetInput = useCallback(() => {
        resetEditorValue(editor);
        setFiles([]);
        setAttachments([]);
        setValue(createInitialValue());
        setHasError(false);
    }, []);

    const canSend = useMemo(
        () =>
            !disabled &&
            (!!files.length || !!attachments.length || !isEmpty(value)),
        [value, disabled, files, attachments],
    );

    const handleSubmit = useCallback(() => {
        if (!canSend) {
            return;
        }

        resetInput();
        setTemplate(undefined);
        if (typeof onSend === 'function') {
            const data: EditorData = {
                message: serializer.serialize(value),
                messageTemplateId: template?.externalId
                    ? template.id
                    : undefined,
                value,
                sendAt,
            };

            onSend({ ...data, attachments });
        }
    }, [template, value, files, attachments, onSend, sendAt]);

    const handleScheduledSubmit = async (date: Date) => {
        try {
            if (typeof onSend === 'function') {
                const data = {
                    message: serializer.serialize(value),
                    value,
                    sendAt: date.toString(),
                };

                await onSend({ ...data, attachments });
            }

            resetInput();
        } catch (e) {
            setHasError(true);
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit();
        }
    };

    const handleBlur = () => {
        if (typeof onBlur === 'function') {
            onBlur(value);
        }

        if (isEmpty(value)) {
            resetInput();
        }
    };

    const handleCancel = useCallback(() => {
        onCancel?.();
        resetInput();
    }, [onCancel]);

    const handleChange = useCallback(
        (nextValue: Descendant[]) => {
            const isSame = value === nextValue;
            setHasError(false);
            setValue(nextValue);
            if (typeof onChange === 'function' && !isSame) {
                onChange({ value: nextValue, files, attachments });
            }

            emojiChangeHandler();
        },
        [files, attachments],
    );

    const handleUpload = useCallback(
        (file: File) => {
            setQueued((prev) => prev.concat(file));
            uploadAttachment(file)
                .then((attachment) => {
                    setAttachments((prev) => prev.concat(attachment));
                })
                .finally(() => setQueued((prev) => reject(prev, file)));
        },
        [uploadAttachment],
    );

    const handleSnippetSelected = useCallback(
        (template: MessageTemplate) => {
            if (template.externalId) {
                resetInput();
            }
            setTemplate(template);
            insertSnippet(editor, template.body, propertiesRegEx);
            ReactEditor.focus(editor);
        },
        [editor],
    );

    const unselectTemplate = useCallback(() => {
        resetInput();
        setTemplate(undefined);
    }, [editor]);

    const externalTemplateSelected = useMemo(
        () => !!template?.externalId,
        [template],
    );

    const aiAssistantEnabledForInbox =
        withAiAssistant &&
        messages.length > 0 &&
        !isConversationPending &&
        !!conversation?.inbox?.assistantEnabled;

    const aiPipelineEnabledForInbox =
        withAiAssistant &&
        !isConversationPending &&
        (conversation?.inbox?.aiPipelineCount || 0) > 0;

    const handleFocus = () => {
        const state = searchParams.get(QueryParam.ContactInfo);
        if (isBetweenMdXl && state !== 'false') {
            goToQueryParam(
                {
                    [QueryParam.ContactInfo]: 'false',
                },
                [QueryParam.DiscussionId],
            );
        }
    };

    return (
        <>
            <Slate editor={editor} initialValue={value} onChange={handleChange}>
                <Box sx={{ position: 'relative' }}>
                    <Stack
                        spacing={3}
                        ref={containerRef}
                        sx={{
                            ...styles.root,
                            height,
                        }}
                        className={cc({
                            disabled,
                            canSend,
                            error: hasError,
                            assistant:
                                (aiAssistantEnabledForInbox ||
                                    aiPipelineEnabledForInbox) &&
                                !conversation?.assistantDisabled,
                        })}
                    >
                        <Box sx={styles.editableContainer} flex={1}>
                            <Box
                                {...editableProps}
                                component={Editable}
                                onKeyDown={emojiKeyDownHandler}
                                onKeyPress={handleKeyPress}
                                renderLeaf={renderLeaf}
                                renderElement={renderElement}
                                onBlur={handleBlur}
                                sx={styles.editable}
                                decorate={linkDecorator}
                                readOnly={disabled || externalTemplateSelected}
                                renderPlaceholder={Placeholder}
                                onFocus={handleFocus}
                            />
                        </Box>

                        {withFileUploader &&
                            (!!attachments.length || !!queued.length) && (
                                <Stack spacing={1} sx={styles.attachments}>
                                    <AttachmentList
                                        attachments={attachments}
                                        removeAttachment={(attachment) =>
                                            removeFile(attachment.id)
                                        }
                                    >
                                        <QueueList files={queued} />
                                    </AttachmentList>
                                </Stack>
                            )}
                    </Stack>
                    <SearchEmojiPicker
                        search={emojiSearchString}
                        searchIndex={emojiSearchIndex}
                        targetRange={emojiTargetRange}
                        onInsert={handleInsertEmoji}
                        emojis={emojis}
                    />
                    <Toolbar
                        isTransparent={transparentToolbar}
                        onCancel={handleCancel}
                        edit={edit}
                        disabled={disabled}
                        handleUpload={handleUpload}
                        externalTemplateSelected={externalTemplateSelected}
                        toolbar={toolbar}
                        propertiesRegEx={propertiesRegEx}
                        template={template}
                        unselectTemplate={unselectTemplate}
                        handleSnippetSelected={handleSnippetSelected}
                        withAiAssistant={withAiAssistant}
                        withFileUploader={withFileUploader}
                        aiAssistantEnabledForInbox={aiAssistantEnabledForInbox}
                        aiPipelineEnabledForInbox={aiPipelineEnabledForInbox}
                        conversation={conversation}
                        containerRef={containerRef}
                        sendAt={sendAt}
                        onScheduledSubmit={handleScheduledSubmit}
                        onSubmit={handleSubmit}
                        canSend={canSend}
                        withSendButton={withSendButton}
                    />
                </Box>
            </Slate>
            {dropZoneParent &&
                createPortal(<DropZone onDrop={onDrop} />, dropZoneParent)}
        </>
    );
}
