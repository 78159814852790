import React, { useState, createContext, useContext } from 'react';
import { InboxProvider, WebexPhone } from '../../../api/types';

interface SetupWebexInboxContextProps {
    selectedPhones: WebexPhone[];
    setSelectedPhones: React.Dispatch<React.SetStateAction<WebexPhone[]>>;
    carrier: InboxProvider.WEBEX;
}

export const SetupWebexInboxContext = createContext<
    SetupWebexInboxContextProps | undefined
>(undefined);

export const SetupWebexInboxProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [selectedPhones, setSelectedPhones] = useState<WebexPhone[]>([]);
    return (
        <SetupWebexInboxContext.Provider
            value={{
                selectedPhones,
                setSelectedPhones,
                carrier: InboxProvider.WEBEX,
            }}
        >
            {children}
        </SetupWebexInboxContext.Provider>
    );
};

export const useSetupWebexInboxContext = () => {
    const context = useContext(SetupWebexInboxContext);
    if (!context) {
        throw new Error('useInboxContext must be used within an InboxProvider');
    }
    return context;
};
