import {
    Box,
    Divider,
    List,
    ListSubheader,
    SxProps,
    Theme,
} from '@mui/material';
import { ClerkPermission, Feature } from '../../../api/types';
import { WithPermission } from '../../WithPermission/WithPermission';
import { useEnabledFeature, useMeQueryData } from '../../../queries/user';
import { SubscribersOnly } from '../../SubscribersOnly/SubscribersOnly';
import Icon from '../../../icons/Icon';
import { BrandRegistrationIcon } from './icons/BrandRegistrationIcon';
import { UserIcon } from './icons/UserIcon';
import { IntegrationsIcon } from './icons/IntegrationsIcon';
import { LogsIcon } from './icons/LogsIcon';
import { BlacklistIcon } from './icons/BlacklistIcon';
import { ApiKeyIcon } from './icons/ApiKeyIcon';
import { KnowledgeBaseIcon } from './icons/KnowledgeBaseIcon';
import NavigationItem from '../../../elements/Navigation';
import { WidgetIcon } from './icons/WidgetIcon';
import { WithFeature } from '../../Feature/WithFeature';
import { NotificationsIcon } from './icons/NotificationsIcon';
import { Members } from '../../../icons/common/CMembers';
import { CompanyIcon } from '../../../icons/common/CCompanyIcon';

const navStyle: SxProps<Theme> = {
    width: 1,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
};

export const Navigation = ({ setMenuOpen }: { setMenuOpen: () => void }) => {
    const me = useMeQueryData();
    const enabled = useEnabledFeature(Feature.BrandOptInIntegration);

    return (
        <Box component="nav" sx={navStyle}>
            <List subheader={<ListSubheader>Account</ListSubheader>}>
                <NavigationItem
                    to="/settings/profile"
                    label="Profile"
                    icon={<UserIcon />}
                    onClick={setMenuOpen}
                />
            </List>
            <Divider />

            <List subheader={<ListSubheader>Workspace</ListSubheader>}>
                <WithPermission
                    requiredPermissions={[ClerkPermission.ModifyTeam]}
                >
                    <NavigationItem
                        to="/settings/team"
                        label="Team info"
                        icon={<CompanyIcon />}
                        onClick={setMenuOpen}
                    />
                </WithPermission>
                <WithPermission
                    requiredPermissions={[
                        enabled
                            ? ClerkPermission.BrandAccess
                            : ClerkPermission.ModifyTeam,
                    ]}
                >
                    <SubscribersOnly>
                        <NavigationItem
                            to="/settings/compliance"
                            label="Brand Registration"
                            icon={<BrandRegistrationIcon />}
                            onClick={setMenuOpen}
                        />
                    </SubscribersOnly>
                </WithPermission>
                <WithFeature feature={Feature.InviteTeammate}>
                    <WithPermission
                        requiredPermissions={[ClerkPermission.ModifyTeam]}
                    >
                        <NavigationItem
                            to="/settings/members"
                            label="Members"
                            icon={<Members />}
                            onClick={setMenuOpen}
                        />
                    </WithPermission>
                </WithFeature>
                <WithPermission
                    requiredPermissions={
                        me?.activeTeam.integrationsHiddenByDefault
                            ? [ClerkPermission.ModifyTeam]
                            : [ClerkPermission.ViewPublicInbox]
                    }
                >
                    <NavigationItem
                        to="/settings/integrations"
                        label="Integrations"
                        icon={<IntegrationsIcon />}
                        onClick={setMenuOpen}
                    />
                </WithPermission>
                <WithFeature feature={Feature.ManageBilling}>
                    {(!me?.activeTeam.billingProvider ||
                        me?.activeTeam.billingProvider === 'stripe') && (
                        <NavigationItem
                            to="/settings/billing"
                            label="Billing"
                            icon={<Icon name="custom-wallet" />}
                            onClick={setMenuOpen}
                        />
                    )}
                </WithFeature>
                <WithPermission
                    requiredPermissions={[ClerkPermission.ModifyTeam]}
                >
                    <WithFeature feature={Feature.AiWorkflow}>
                        <NavigationItem
                            to="/settings/knowledge-base"
                            label="Knowledge Base"
                            icon={<KnowledgeBaseIcon />}
                            onClick={setMenuOpen}
                        />
                    </WithFeature>
                </WithPermission>
                <NavigationItem
                    onClick={setMenuOpen}
                    to="/settings/notifications"
                    label="Notifications"
                    icon={<NotificationsIcon />}
                />
            </List>
            <Divider />

            <WithPermission requiredPermissions={[ClerkPermission.ModifyTeam]}>
                <List subheader={<ListSubheader>Data</ListSubheader>}>
                    <NavigationItem
                        to="/settings/export"
                        label="Logs"
                        icon={<LogsIcon />}
                    />
                    <NavigationItem
                        to="/settings/profanity"
                        label="Blacklist"
                        icon={<BlacklistIcon />}
                        onClick={setMenuOpen}
                    />
                    <NavigationItem
                        to="/settings/api"
                        label="API"
                        icon={<ApiKeyIcon />}
                        onClick={setMenuOpen}
                    />
                    <NavigationItem
                        to="/settings/widget"
                        label="Clerk Widget"
                        icon={<WidgetIcon />}
                        onClick={setMenuOpen}
                    />
                </List>
            </WithPermission>
            <Divider />
        </Box>
    );
};
