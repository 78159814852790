import {
    InfiniteData,
    useInfiniteQuery,
    useMutation,
    useQuery,
} from '@tanstack/react-query';
import {
    deleteMessage,
    deleteMessageUnfurl,
    getMessages,
} from '../api/messages';
import { queryClient } from './queryClient';
import { Conversation, Message } from '../api/types';
import { PagedData } from '../types/PagedData';
import { UUID } from '../types/uuid';
import { useTrack } from '../contexts/analytics';
import { CONVERSATIONS_LIST_KEY, prefetchConversation } from './conversations';
import client from '../api/http';
import { AxiosError } from 'axios';
import omit from 'lodash/omit';
import { useParams } from 'react-router';

export const CONVERSATION_MESSAGES_KEY = 'conversation_messages_list';
export const UNREADS_COUNT_KEY = 'unreads_count';

type UnreadCount = { inboxId: UUID; count: number };
export const useUnreadMessageCounts = (select: (x: UnreadCount[]) => number) =>
    useQuery({
        queryKey: [UNREADS_COUNT_KEY],
        staleTime: 200,
        gcTime: 5 * 60_000,
        queryFn: () =>
            client()
                .get<UnreadCount[]>('/messages/unread_counts')
                .then(({ data }) => data),
        select,
    });

export const useUnreadMessagesCountForInbox = (inboxId: string) =>
    useUnreadMessageCounts((counts = []) => {
        const rows = counts.filter((item) => item.inboxId === inboxId);
        return rows.length === 1 ? rows[0].count : 0;
    });

const getNextPageParam = (lastPage: Message[] | undefined) => {
    if (lastPage && lastPage[0]) {
        return new Date(lastPage[0].timestamp).getTime();
    }
    return undefined;
};

export const useConversationMessages = (conversationId: UUID) =>
    useInfiniteQuery({
        queryKey: [CONVERSATION_MESSAGES_KEY, conversationId],
        queryFn: ({ pageParam }) => getMessages(conversationId, pageParam),
        initialPageParam: undefined as undefined | number,
        getNextPageParam,
        staleTime: 5_000,
    });

const prefetchMessages = (conversationId: string) =>
    queryClient.prefetchInfiniteQuery({
        queryKey: [CONVERSATION_MESSAGES_KEY, conversationId],
        queryFn: ({ pageParam }) => getMessages(conversationId, pageParam),
        initialPageParam: undefined,
        getNextPageParam,
        pages: 1,
        staleTime: 15_000,
    });

export const prefetchConversationPage = (conversationId: UUID) => {
    if (!conversationId) {
        return;
    }
    return Promise.all([
        prefetchConversation(conversationId),
        prefetchMessages(conversationId),
    ]);
};

/** @depreacted use useConversationMessages */
export const useGetMessages = (conversationId: UUID) => {
    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetched,
        isFetchingNextPage,
        isPending,
    } = useConversationMessages(conversationId);

    /*todo: flat reverse??*/
    const newData =
        data?.pages.reduce((acc, msgs) => [...msgs, ...acc], []) ?? [];
    return {
        messages: newData,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isFetched,
        isPending,
    };
};

export const useDeleteMessageUnfurl = () => {
    const track = useTrack();

    return useMutation({
        mutationFn: (message: {
            messageId: number;
            unfurlId: string;
            conversationId: string;
        }): Promise<void> => deleteMessageUnfurl(message),
        onMutate: async ({ conversationId }) => {
            await queryClient.cancelQueries({
                queryKey: [CONVERSATION_MESSAGES_KEY, conversationId],
            });
        },
        onSuccess: (_, { conversationId, messageId, unfurlId }) => {
            track('message_unfurl_deleted');
            queryClient.setQueriesData<InfiniteData<Message[]>>(
                {
                    queryKey: [CONVERSATION_MESSAGES_KEY, conversationId],
                },
                (prev) => {
                    if (!prev?.pages) {
                        return prev;
                    }
                    return {
                        ...prev,
                        pages: prev.pages.map((page) =>
                            page.map((message) => {
                                if (message.id === messageId) {
                                    return {
                                        ...message,
                                        unfurl: omit(message.unfurl, unfurlId),
                                    };
                                }
                                return message;
                            }),
                        ),
                    };
                },
            );
        },
    });
};

export const useDeleteMessage = (id?: number) => {
    const track = useTrack();
    const { inboxId } = useParams();
    return useMutation<
        void,
        AxiosError,
        { messageId: number; conversationId: string }
    >({
        mutationKey: ['delete_message', id],
        mutationFn: (message) => deleteMessage(message),
        onMutate: async ({ messageId, conversationId }) => {
            await queryClient.cancelQueries({
                queryKey: [CONVERSATION_MESSAGES_KEY, conversationId],
            });

            return {
                conversationId,
                messageId,
            };
        },
        onSuccess: async (_, { conversationId }) => {
            track('message_deleted');
            await queryClient.refetchQueries({
                queryKey: [CONVERSATION_MESSAGES_KEY, conversationId],
            });
            const messagesData = queryClient.getQueryData<
                InfiniteData<Message[]>
            >([CONVERSATION_MESSAGES_KEY, conversationId]);
            if (messagesData?.pages[0].length === 0) {
                await queryClient.refetchQueries({
                    queryKey: [CONVERSATIONS_LIST_KEY, inboxId],
                });
            }
        },
    });
};

export const clearAssistant = (conversationId: UUID, inboxId?: UUID): void => {
    queryClient.setQueriesData<PagedData<Conversation>>(
        {
            queryKey: inboxId
                ? [CONVERSATIONS_LIST_KEY, inboxId]
                : [CONVERSATIONS_LIST_KEY],
        },
        (prev) => {
            if (!prev?.pages) {
                return prev;
            }
            return {
                ...prev,
                pages: prev.pages.map((page) =>
                    page.map((conversation) => {
                        if (conversation.id === conversationId) {
                            return {
                                ...conversation,
                                assistantMessageId: null,
                                assistantTimestamp: null,
                            };
                        }
                        return conversation;
                    }),
                ),
            };
        },
    );
};
