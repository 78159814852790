import { indicatorsHidden } from './styles';
import { Box, Stack } from '@mui/material';
import { Inbox, InboxStatus } from '../../api/types';
import Tooltip from '../Tooltip';
import { Indicator } from '../../components/common/Indicator';
import { useUnreadMessagesCountForInbox } from '../../queries/messages';
import AttentionIcon from '../../assets/icons/different/attention.svg?react';
import GreenCheckIcon from '../../assets/icons/different/greencheck.svg?react';
import { getStatusText } from './utils';

type Props = {
    isHidden: boolean;
    inbox: Inbox;
};

export function Indicators({ isHidden = false, inbox }: Props) {
    const { status, id } = inbox;
    const unreadCount = useUnreadMessagesCountForInbox(id).data || 0;

    return (
        <Stack
            pr={2}
            spacing={1}
            alignItems="end"
            className="indicators"
            sx={isHidden ? indicatorsHidden : undefined}
        >
            <Stack direction="row" spacing={1} alignItems="center">
                {status !== InboxStatus.ACTIVE && (
                    <Tooltip width="150px" text={getStatusText(status, inbox)}>
                        <Box>
                            <GreenCheckIcon />
                        </Box>
                    </Tooltip>
                )}
                {status === InboxStatus.DISABLED && (
                    <Tooltip
                        width="150px"
                        text="This phone number has been disabled"
                    >
                        <AttentionIcon />
                    </Tooltip>
                )}
            </Stack>
            {unreadCount > 0 && (
                <Indicator data-testid="inbox-unread-count">
                    {unreadCount < 99 ? unreadCount : '99+'}
                </Indicator>
            )}
        </Stack>
    );
}
