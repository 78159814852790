import { Time24Format } from '../../types';
import { OptionBase } from '../../../../elements/AdvancedSelect';
import { ianaTimeZones } from '../../../../utils/iana-timezones';
import dayjs from 'dayjs';

export const AM_PM: OptionBase[] = [
    {
        id: 'am',
        label: 'AM',
    },
    {
        id: 'pm',
        label: 'PM',
    },
];

export const DAYS_OF_WEEK = [
    { id: '1', label: 'Mon', fullName: 'Monday' },
    { id: '2', label: 'Tue', fullName: 'Tuesday' },
    { id: '3', label: 'Wed', fullName: 'Wednesday' },
    { id: '4', label: 'Thu', fullName: 'Thursday' },
    { id: '5', label: 'Fri', fullName: 'Friday' },
    { id: '6', label: 'Sat', fullName: 'Saturday' },
    { id: '0', label: 'Sun', fullName: 'Sunday' },
] as const;

export type TimeSelectItem = Time24Format & { id: string; label: string };

const TIME_STEP_MINUTES = 30;

export const getTimeList = () => {
    const init = dayjs().startOf('day');
    const records: TimeSelectItem[] = [];

    let counter = init.clone();
    while (counter.isSame(init, 'date')) {
        const hours = counter.get('hours');
        const minutes = counter.get('minutes');
        records.push({
            id: `${hours}:${minutes}`,
            label: counter.format('hh:mm a'),
            hours,
            minutes,
        });
        counter = counter.add(TIME_STEP_MINUTES, 'minutes');
    }

    return records;
};

export const TIMEZONES: OptionBase[] = ianaTimeZones.map((tz) => ({
    id: tz,
    label: tz,
}));
