import { ContactsIcon } from '../../../icons/common/CContactsIcon';
import { AnalyticsIcon } from '../../SideBar/SideBarNavigation/AnalyticsIcon';
import { SettingsIcon } from '../../../components/Inbox/CSettingsIcon';
import { WorkflowsIcon } from '../../../icons/common/CWorkflows';
import { UserIcon } from '../../SettingsLayout/Navigation/icons/UserIcon';
import { BrandRegistrationIcon } from '../../SettingsLayout/Navigation/icons/BrandRegistrationIcon';
import { IntegrationsIcon } from '../../SettingsLayout/Navigation/icons/IntegrationsIcon';
import { KnowledgeBaseIcon } from '../../SettingsLayout/Navigation/icons/KnowledgeBaseIcon';
import { NotificationsIcon } from '../../SettingsLayout/Navigation/icons/NotificationsIcon';
import { LogsIcon } from '../../SettingsLayout/Navigation/icons/LogsIcon';
import { BlacklistIcon } from '../../SettingsLayout/Navigation/icons/BlacklistIcon';
import { ApiKeyIcon } from '../../SettingsLayout/Navigation/icons/ApiKeyIcon';
import { WidgetIcon } from '../../SettingsLayout/Navigation/icons/WidgetIcon';
import { Groups2, Wallet } from '@mui/icons-material';
import { Users } from 'phosphor-react';
import { PhoneIcon } from '../../../icons/common/CPhoneIcon';
import { FC } from 'react';
import { BlockIcon } from '../../../icons/common/BlockIcon';

type NavItem = {
    title: string;
    icon: FC;
    to: string;
    aliases: string[];
};

export const brandNavItem: NavItem = {
    title: 'Brand Registration',
    icon: BrandRegistrationIcon,
    to: '/settings/compliance',
    aliases: [
        'Trademark',
        'Branding',
        'Compliance',
        'Registration',
        'Verification',
        'Campaign ID',
        'Opt-in',
    ],
};

export const items: NavItem[] = [
    {
        title: 'Contacts',
        icon: ContactsIcon,
        to: '/contacts/book',
        aliases: [
            'Address book',
            'Phones',
            'People',
            'Directory',
            'Contact list',
        ],
    },
    {
        title: 'Cohorts',
        icon: ContactsIcon,
        to: '/contacts/cohorts',
        aliases: ['Cohort', 'Cohorts', 'People', 'Group', 'Groups'],
    },
    {
        title: 'Analytics',
        icon: AnalyticsIcon,
        to: '/analytics/messages',
        aliases: ['Stats', 'Statistic', 'Data', 'Metrics', 'Reports'],
    },
    {
        title: 'Settings',
        icon: SettingsIcon,
        to: '/settings/profile',
        aliases: ['Configuration', 'Preferences', 'Options', 'Controls'],
    },
    {
        title: 'Block List',
        icon: BlockIcon,
        to: '/contacts/block-list',
        aliases: [
            'Block list',
            'Adware',
            'Blacklist',
            'Phishing',
            'Blocked',
            'Missing',
            'Hidden',
            'Unwanted',
            'Ignore',
            'Fraud',
            'Ban',
            'Spam',
        ],
    },
    {
        title: 'Workflows',
        icon: WorkflowsIcon,
        to: '/workflows',
        aliases: ['Processes', 'Automation', 'Tasks', 'Flows'],
    },
    {
        title: 'Profile',
        icon: UserIcon,
        to: '/settings/profile',
        aliases: [
            'Account',
            'User',
            'Personal Info',
            'Details',
            'Profile',
            'Avatar',
            'Email',
            'Signature',
        ],
    },
    {
        title: 'Team info',
        icon: Groups2,
        to: '/settings/team',
        aliases: [
            'Team',
            'Avatar',
            'Workspace',
            'Organization',
            'Group',
            'Company Info',
        ],
    },
    {
        title: 'Members',
        icon: Users,
        to: '/settings/members',
        aliases: [
            'Users',
            'Team Members',
            'Participants',
            'Users List',
            'Invite',
        ],
    },
    {
        title: 'Integrations',
        icon: IntegrationsIcon,
        to: '/settings/integrations',
        aliases: [
            'Connections',
            'Plugins',
            'Extensions',
            'Third-party',
            'HubSpot',
            'Salesforce CRM',
            'Microsoft Outlook',
            'Microsoft Dynamics',
            'CSV Import',
            'Shopify',
            'Global Relay',
            'Campaign Registry',
            'Microsoft Azure Storage',
            'PipeDrive',
            'Slack',
            'Google Contacts',
            'Smarsh',
            'LinkedIn',
            'Stripe',
            'Purview',
            'Calling Statistics',
        ],
    },
    {
        title: 'Knowledge Base',
        icon: KnowledgeBaseIcon,
        to: '/settings/knowledge-base',
        aliases: [
            'AI',
            'Assistant',
            'Help Center',
            'Documentation',
            'Support',
            'Delay',
            'Stop words',
            'Assistant instruction',
            'Until review',
            'Immediately',
        ],
    },
    {
        title: 'Notifications',
        icon: NotificationsIcon,
        to: '/settings/notifications',
        aliases: ['Alerts', 'Messages', 'Updates', 'Reminders'],
    },
    {
        title: 'Logs',
        icon: LogsIcon,
        to: '/settings/export',
        aliases: ['Records', 'History', 'Activity', 'Export Data', 'CSV'],
    },
    {
        title: 'Add Inbox',
        icon: PhoneIcon,
        to: '/setup-inbox/existing-number',
        aliases: ['Inbox', 'Phone', 'Twilio', 'Zoom', 'Webex', 'Telnyx'],
    },
    {
        title: 'Blacklist',
        icon: BlacklistIcon,
        to: '/settings/profanity',
        aliases: [
            'Blocklist',
            'Banned',
            'Restricted',
            'Prohibited',
            'Profanity',
        ],
    },
    {
        title: 'Billing',
        icon: Wallet,
        to: '/settings/billing',
        aliases: ['Payments', 'Invoices', 'Subscription', 'Finance'],
    },
    {
        title: 'API',
        icon: ApiKeyIcon,
        to: '/settings/api',
        aliases: ['Developer', 'Integration', 'Endpoint', 'Access'],
    },
    {
        title: 'Clerk Widget',
        icon: WidgetIcon,
        to: '/settings/widget',
        aliases: ['Widget', 'Tool', 'Component', 'Addon', 'Form builder'],
    },
];
