import { useNavigate } from 'react-router';
import {
    DragDropContext,
    Droppable,
    DroppableProvided,
    DropResult,
} from '@hello-pangea/dnd';
import {
    ClerkPermission,
    Feature,
    Inbox,
    UserTeamSettingType,
} from '../../../api/types';
import CollapsibleSection from '../../../components/CollapsibleSection';
import useUnmutedSortedInboxes from '../../../hooks/useUnmutedSortedInboxes';
import { useEnabledFeature, useUpdateMeSettings } from '../../../queries/user';
import { WithPermission } from '../../WithPermission/WithPermission';
import { AnalyticsActions, useAnalytics } from '../../../contexts/analytics';
import { useCallback } from 'react';
import { Box, Link } from '@mui/material';
import { PlusIcon } from '../../../icons/common/CPlusIcon';
import { createInboxLink, createInboxLinkIcon, list } from './styles';
import { InboxDraggableItem } from '../../../elements/InboxItem/InboxDraggableItem';

interface SideBarInboxesProps {
    setMenuOpen: (value: boolean) => void;
}

export const SideBarInboxes = ({ setMenuOpen }: SideBarInboxesProps) => {
    const { unmutedSortedInboxes } = useUnmutedSortedInboxes();
    const { dispatch } = useAnalytics();
    const { mutate: updateSettings } = useUpdateMeSettings();
    const navigate = useNavigate();

    const handleOnDragEnd = useCallback(
        (result: DropResult) => {
            if (!result.destination) {
                return;
            }

            const newInboxesOrder = Array.from(unmutedSortedInboxes);
            const [reorderedItem] = newInboxesOrder.splice(
                result.source.index,
                1,
            );
            newInboxesOrder.splice(result.destination.index, 0, reorderedItem);

            updateSettings({
                enabled: true,
                type: UserTeamSettingType.InboxesOrder,
                data: {
                    ids: newInboxesOrder.map((inbox) => inbox.id),
                },
            });
        },
        [unmutedSortedInboxes, updateSettings],
    );

    const handleInboxItemClick = useCallback(() => {
        setMenuOpen(false);
    }, [setMenuOpen]);
    const canCreateNewNumber = useEnabledFeature(Feature.CreateNewNumber);
    const canImportNumber = useEnabledFeature(Feature.ImportNumber);
    const canAddInbox = useEnabledFeature(Feature.AddInbox);

    const handleAddButtonClick = useCallback(() => {
        dispatch({
            type: AnalyticsActions.TRACK,
            payload: {
                eventName: 'clicked_create_new_inbox',
            },
        });
        (!canCreateNewNumber && !canImportNumber) || !canAddInbox
            ? navigate('/payment/plan')
            : navigate('/setup-inbox/existing-number');
    }, [dispatch, canCreateNewNumber, canImportNumber, canAddInbox, navigate]);

    return (
        <CollapsibleSection title="Phones" initiallyExpanded>
            <WithPermission requiredPermissions={[ClerkPermission.ModifyTeam]}>
                <Box px={4} py={2} mt={2}>
                    <Link
                        sx={createInboxLink}
                        color="info.main"
                        onClick={handleAddButtonClick}
                        underline="hover"
                    >
                        <PlusIcon sx={createInboxLinkIcon} /> Add new phone
                    </Link>
                </Box>
            </WithPermission>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="inboxes-list">
                    {(providedDroppable: DroppableProvided) => (
                        <Box
                            component="ul"
                            sx={list}
                            ref={providedDroppable.innerRef}
                            {...providedDroppable.droppableProps}
                        >
                            {unmutedSortedInboxes.map((inbox: Inbox, i) => (
                                <InboxDraggableItem
                                    key={inbox.id}
                                    index={i}
                                    inbox={inbox}
                                    onClick={handleInboxItemClick}
                                />
                            ))}
                            {providedDroppable.placeholder}
                        </Box>
                    )}
                </Droppable>
            </DragDropContext>
        </CollapsibleSection>
    );
};
