import { useState } from 'react';
import SideBarInboxes from './SideBarInboxes';
import SideBarMutedInboxes from './SideBarMutedInboxes';
import TierUpgradeOverlay from '../../components/TierUpgradeOverlay';
import SideBarInvite from './SideBarInvite';
import { Box, IconButton, Stack } from '@mui/material';
import { WithPermission } from '../WithPermission/WithPermission';
import { ClerkPermission, Feature } from '../../api/types';
import { useMatch } from 'react-router';
import { SideBarNavigation } from './SideBarNavigation/SideBarNavigation';
import { TeamSelector } from '../../components/TeamSelector/TeamSelector';
import { useMeQueryData } from '../../queries/user';
import { MenuIcon } from '../SettingsLayout/MenuIcon';
import { HEADER_HEIGHT } from '../../pages/NewInbox/inbox.styles';
import { colors } from '../../theme/palette';
import { CloseIcon } from '../../icons/common/CCloseIcon';
import { HideUpMd } from '../../components/MediaHelpers/HideUpMd';
import { getSquare } from '../../theme/style.helpers';
import { useTrack } from '../../contexts/analytics';
import { aside, content, section, topRow } from './styles';
import { WithFeature } from '../Feature/WithFeature';
import { useBetweenMdLg } from '../../hooks/useBetweenMdLg';
import { InboxesPopover } from './InboxesPopover';
import { HideDownMd } from '../../components/MediaHelpers/HideDownMd';

export const SideBar = () => {
    const track = useTrack();
    const isFree = useMeQueryData()?.activeTeam?.tier === 'free';
    const [isMenuOpen, setMenuOpen] = useState(false);
    const isNarrowScreen = useBetweenMdLg();

    return (
        <Box component="aside" sx={aside}>
            <Stack gap={3} direction="row" sx={topRow}>
                <TeamSelector />
                <HideUpMd>
                    <Box sx={{ bgcolor: colors.primary.gray.active }}>
                        <IconButton
                            sx={getSquare(HEADER_HEIGHT)}
                            onClick={() => {
                                track('sidebar_menu_toggled', {
                                    open: !isMenuOpen,
                                });
                                setMenuOpen((prev) => !prev);
                            }}
                        >
                            {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
                        </IconButton>
                    </Box>
                </HideUpMd>
            </Stack>
            <Box sx={content} className={isMenuOpen ? 'isMenuOpen' : undefined}>
                <SideBarNavigation setMenuOpen={setMenuOpen} />

                <Box sx={section}>
                    <WithPermission
                        requiredPermissions={[ClerkPermission.AddTeamMembers]}
                    >
                        <WithFeature feature={Feature.InviteTeammate}>
                            <HideDownMd>
                                <SideBarInvite />
                            </HideDownMd>
                        </WithFeature>
                    </WithPermission>
                    <WithPermission
                        requiredPermissions={[
                            ClerkPermission.ViewInboxMessages,
                        ]}
                    >
                        {isNarrowScreen ? (
                            <InboxesPopover />
                        ) : (
                            <>
                                <SideBarInboxes setMenuOpen={setMenuOpen} />
                                <SideBarMutedInboxes
                                    setMenuOpen={setMenuOpen}
                                />
                            </>
                        )}
                    </WithPermission>

                    <Box mt="auto">
                        {isFree && !isNarrowScreen && <UpgradeComponent />}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

const UpgradeComponent = () => {
    const isMatch = useMatch('/payment/*');
    return (
        !isMatch && (
            <Box pt={4}>
                <TierUpgradeOverlay />
            </Box>
        )
    );
};

export default SideBar;
