import { Link, Stack } from '@mui/material';
import { MailPlus } from '../../../icons/common/CMailPlus';
import { getSquare } from '../../../theme/style.helpers';
import { useBetweenMdLg } from '../../../hooks/useBetweenMdLg';

export function InviteButton({ onClick }: { onClick: () => void }) {
    const isNarrowScreen = useBetweenMdLg();
    return (
        <Link onClick={onClick}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent={isNarrowScreen ? 'center' : undefined}
                spacing={4}
            >
                <MailPlus sx={getSquare(20)} />{' '}
                {!isNarrowScreen && <span>Invite team</span>}
            </Stack>
        </Link>
    );
}
